import React, { useState } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../platform/constants/routes";

import Modal from "../Modal";
import Register from "../Register";

import logo from "../../assets/images/logo.svg";

import "./style.scss";

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const year = new Date().getFullYear();

  return (
    <footer className="G-flex G-align-center G-justify-between">
      <div className="G-flex">
        <Link to={ROUTES.HOME}>
          <img
            width="140"
            height="140"
            src={logo}
            alt="logo"
            className="logo"
            loading="lazy"
          />
        </Link>
        <div className="G-flex G-flex-column gracner G-ml-6">
          <Link to={ROUTES.COURSES} className="G-mb-2">
            Մեր դասընթացները
          </Link>
          <Link to={ROUTES.BLOG_LIST}>Բլոգ</Link>
        </div>
        <div className="G-flex G-flex-column gracner">
          <Link to={ROUTES.ABOUT} className="G-mb-2">
            Մեր մասին
          </Link>
          <p onClick={() => setIsOpen(true)} className="G-mb-2">
            Գրանցվել
          </p>
          <Link to={ROUTES.FAQ}>ՀՏՀ</Link>
        </div>
      </div>

      <div className="G-flex G-flex-column">
        <div className="G-flex G-align-center G-justify-between G-flex-column nkarner">
          <div className="G-flex G-align-center G-justify-between">
            | &nbsp;
            <a
              href="https://www.facebook.com/openskyitschool"
              target="blank"
              rel="noopener"
            >
              Facebook
            </a>
            | &nbsp;
            <a
              href="https://www.instagram.com/opensky_it_school/"
              target="blank"
              rel="noopener"
            >
              Instagram
            </a>
            | &nbsp;
            <a
              href="https://www.linkedin.com/company/opensky-it-school/"
              target="blank"
              rel="noopener"
            >
              LinkedIn
            </a>
          </div>
          <div className="P-footer-address G-mt-2 G-flex G-align-center">
            <h2>Զաքյան 8, &nbsp;</h2>
            <a href="tel:+37495821084"> +374 95 82 10 84</a>
          </div>
        </div>
      </div>
      <span>© {year} Open Sky IT-school. Բոլոր իրավունքները պաշտպանված են</span>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Register onClose={() => setIsOpen(false)} />
      </Modal>
    </footer>
  );
}

export default Footer;
