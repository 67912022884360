import React from "react";
import Thanks from "../../assets/images/Thanks.png";

import './style.scss'

function SuccessMessage(props) {

  return (
    props.isOpen &&
    <div className="P-success-message G-flex G-flex-column G-align-center G-justify-center">
      <img src={Thanks} alt="" loading="lazy" />
      <h2 className="G-mb-1">Շնորհակալություն</h2>
      <h3>Ձեր նամակը հաջողությամբ ուղարկված է, մենք կկապվենք Ձեզ հետ հնարավորինս շուտ:</h3>
    </div>
  );
}

export default SuccessMessage;
