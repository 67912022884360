import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cover from "../../../../assets/images/cover.webp";
import Logo from "../../../../assets/images/new_logo.webp";
import Modal from "../../../../components/Modal";
import Register from "../../../../components/Register";
import ROUTES from "../../../../platform/constants/routes";

import "./style.scss";

function HomeRegister() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="P-home-register G-flex G-align-center G-justify-center G-flex-column">
      <div className="P-register-content G-flex G-align-center">
        <div className="G-flex G-flex-column G-justify-center">
          <h1 style={{ opacity: 0 }}>
            Ծրագրավորման դասընթացներ, ուսումնական կենտրոն, ՏՏ դպրոց
          </h1>
          <div className="G-flex G-align-center G-justify-center P-updated G-mb-3">
            Թարմացված ծրագիր
          </div>
          <h2 className="G-mb-3">
            ԴԱՍԸՆԹԱՑՆԵՐ, <br /> ՈՐՈՆՔ ԿՓՈԽԵՆ ՄԱՍՆԱԳԻՏՈՒԹՅՈՒՆԴ
          </h2>
          <h3 className="G-mb-3">Ժամանակն է որակյալ կրթություն ստանալու</h3>
          <Link to={ROUTES.COURSES} className="G-btn">
            Բոլոր դասընթացները
          </Link>
          <button className="G-btn P-register" onClick={() => setIsOpen(true)}>
            ԳՐԱՆՑՎԵԼ
          </button>
        </div>
        <img src={Logo} alt="" className="P-new_logo" />
        <img className="P-cover" width="846" height="651" src={Cover} alt="" />
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Register onClose={() => setIsOpen(false)} />
      </Modal>
    </div>
  );
}

export default HomeRegister;
