import React, { useEffect, useState } from "react";


import About1 from "../../assets/images/about1.png";
import AboutValues from "../../components/Values";

import TutorController from "../../platform/api/tutor";
import TutorItem from "../../platform/global/tutor-item";

import "./style.scss";

function AboutUs() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let response = await TutorController.GetTutors();
      setData(response.data);
    }

    fetchData();
  }, []);

  return (
    <div className="P-about-us G-flex G-flex-column">
      <div className="P-about-tag G-flex G-align-center G-justify-center G-flex-column">
        <h1>
          Մեզ համար ամենակարեւորը <br /> <span>մասնագիտական աճն է</span>
        </h1>
        <h2>
          Եթե որոշել ես փոխել մասնագիտությունդ, ապա մեր մասնագետները կօգնեն քեզ
        </h2>
        <div className="P-about-circle_1" />
      </div>
      <div className="P-about-goal G-flex G-align-center G-justify-center">
        <div className="P-about-circle_2" />
        <div className="P-goal G-flex G-align-center">
          <div>
            <h2>Մեր նպատակը</h2>
            <h3>Բացահայտիր նոր աշխարհ</h3>
            <p>
              Open Sky-ը Հայաստանում հիմնադրված ուսումնական կենտրոն է, որի
              նպատակն է համախմբել սովորել ցանկացողներին և դասավանդողներին: Ավելի
              քան 3 տարի Open Sky-ում ուսանողները ձեռք են բերում նոր գիտելիքներ
              և հաստատուն քայլերով մուտք գործում աշխատաշուկա: Մեզ համար
              առաջնայինն ուսանողի մասնագիտական ճանապարհն է, դասընթացի սկզբից
              մինչ աշխատանք: Մենք ստեղծել ենք մի մշակույթ, որտեղ դասապրոցեսը
              ձանձրալի չէ, իսկ գիտելիքները մնայուն են: Անկախ նրանից ուզում եք
              սովորել, թե դասավանդել՝ գտնվում եք ճիշտ վայրում:
            </p>
          </div>
          <img src={About1} alt="" />
        </div>
      </div>
      <AboutValues />
      <div className="P-about-team">
        <h2>Մեր թիմը</h2>
        <div className="G-flex G-flex-wrap G-justify-between G-mt-6">
          {data.map((item, index) => (
            <TutorItem data={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
