import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";

import { formatDate } from "../../../../platform/services/format-date";
import { shuffle } from "../../../../platform/services/random";
import CourseController from "../../../../platform/api/course";
import { getImagePath } from "../../../../platform/api/images";
import CourseDetailsItem from "../../../../platform/global/course-details";

import Modal from "../../../../components/Modal";
import Register from "../../../../components/Register";
import Loader from "../../../../components/Loader";

import Arrow from "../../../../assets/images/arrow-down.png";

import "./style.scss";

function CourseDetails(props) {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.match.params;

  useEffect(() => {
    async function fetchDetails() {
      const response = await CourseController.GetDetails(id);
      setDetails(response.data);
    }

    async function fetchData() {
      const response = await CourseController.GetCourseList(1, 1);
      const items = shuffle(response.data.list);
      const filteredItems = items.filter((value, index) => id !== value.slug);
      if (window.innerWidth > 600) {
        setData(filteredItems.slice(0, 3));
      } else {
        setData(filteredItems);
      }
    }

    fetchDetails();
    fetchData();
  }, [id]);

  const intro = [
    {
      title: details?.introductionTitle,
      description: details?.introductionDescription,
    },
    {
      title: details?.introductionTitle2,
      description: details?.introductionDescription2,
    },
    {
      title: details?.introductionTitle3,
      description: details?.introductionDescription3,
    },
  ];

  return (
    <div className="P-course-details">
      {details ? (
        <>
          <div
            style={{
              backgroundImage: `url('${getImagePath(details.coverPhoto)}')`,
            }}
            className="P-course-top G-flex G-flex-end G-align-end"
          >
            <div className="P-background-layer"></div>
            <div className="P-course-title G-flex G-align-center G-justify-center">
              <div>
                <h1>{details.name.hy}</h1>
                <h3 className="G-mt-2">{details.description.hy}</h3>
                <button onClick={() => setIsOpen(true)} className="G-btn">
                  Գրանցվել
                </button>
              </div>
            </div>
            <div className="P-top-footer G-flex G-align-center">
              <div className="G-flex G-align-center G-justify-between">
                <div>
                  <span>Տևողությունը </span>{" "}
                  <span>{details.duration} ամիս</span>
                </div>
                <div>
                  <span>Արժեքը սկսած</span>
                  <span>
                    <span style={{ color: "#008EFF", fontWeight: "bold" }}>
                      {details.newPrice ? details.newPrice + " " : ""}
                    </span>
                    {details.newPrice ? (
                      <del>{details.price + " "}</del>
                    ) : (
                      details.price + " "
                    )}
                    ֏ / ամիսը
                  </span>
                </div>
                <div>
                  <span>Սկիզբը</span> <span>{formatDate(details.start)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="P-course-details-content G-flex G-justify-between">
            <div className="P-course-collapse">
              <p className="G-mb-3">Դասընթացի նկարագիրը</p>
              {intro.map((item, index) => (
                <Collapsible
                  key={index}
                  trigger={
                    item.title && (
                      <>
                        <h3 className="P-title G-mb-3">
                          {item.title}{" "}
                          <img src={Arrow} className="P-arrow" alt="" />
                        </h3>
                      </>
                    )
                  }
                >
                  <h6
                    style={{ whiteSpace: "pre-wrap" }}
                    className="P-description"
                  >
                    {item.description}
                  </h6>
                </Collapsible>
              ))}
            </div>
            <div className="P-details-tutor G-flex G-align-center G-flex-column">
              {details.tutor.map((item, index) => (
                <div className="P-tutor G-flex G-flex-column G-align-center G-justify-center G-mb-4" key={index}>
                  <img
                    className="P-tutor-img"
                    src={getImagePath(item.imagePath)}
                    alt=""
                  />
                  <div className="P-tutor-description">
                    <h2>{item.name.hy}</h2>
                    <h3>{item.workingPosition}</h3>
                    <h4>{item.workingPlace}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="P-details-courses">
            <h2>Այլ դասընթացներ</h2>
            <div className=" G-flex G-align-center G-justify-between G-mt-6">
              {data.map((item, index) => (
                <CourseDetailsItem data={item} key={index} />
              ))}
            </div>
          </div>
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Register onClose={() => setIsOpen(false)} />
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default CourseDetails;
