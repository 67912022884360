import { connection } from '../services/connection';

export default class UserController {

  static sendInfo = data => {
    return connection({
      url    : 'User/info',
      method : 'Post',
      body   : data
    });
  }

  static register = data => {
    return connection({
      url    : 'User/Register',
      method : 'Post',
      body   : data
    });
  }

  static sendEmailToUser = data => {
    return connection({
      url    : 'User/SendEmailToUser',
      method : 'Post',
      body   : data
    });
  }

  static createCV = data => {
    return connection({
      url    : 'User/CreateCV',
      method : 'Post',
      body   : data
    });
  }
}

