import React from "react";
import { Link } from "react-router-dom";
import { getImagePath } from "../../api/images";
import ROUTES from "../../constants/routes";

import "./style.scss";

function CourseItem(data) {
  return (
    <Link
      to={`${ROUTES.COURSE_DETAILS.replace(":id", data?.data?.slug)}`}
      className="P-course-item"
      style={{
        backgroundImage: `url(${getImagePath(data?.data?.coverPhoto)})`,
      }}
    >
      <div className="P-background-layer"></div>
      <div className="P-course-title G-flex G-align-center G-justify-center G-text-center">
        {data?.data?.name?.hy}
      </div>
    </Link>
  );
}

export default CourseItem;
