import React from "react";
import { getImagePath } from "../../api/images";
import { Link } from "react-router-dom";
import { formatDate } from "../../services/format-date";

import "./style.scss";
import ROUTES from "../../constants/routes";

function BlogItem(data) {
  return (
    <Link
      to={`${ROUTES.BLOG_DETAILS.replace(":id", data?.data.slug)}`}
      className="P-blog-item G-mb-5"
      style={{ backgroundImage: `url(${getImagePath(data?.data.imagePath)})` }}
    >
      <div className="P-background-layer"></div>
      <div className="P-blog-info">
        <h2>{data?.data.name.hy}</h2>
        <h3>{data?.data.description.hy.replace("<br/>", "\n")}</h3>
      </div>
      <h4>{formatDate(data.createdAt)}</h4>
    </Link>
  );
}

export default BlogItem;
