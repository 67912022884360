import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../platform/constants/routes";

import "./style.scss";

class Promotion extends React.Component {
  render() {
    return (
      <div className="P-promotion">
        Նոր սկսվող դասընթացներն <Link to={ROUTES.COURSES}>այստեղ</Link>{" "}
      </div>
    );
  }
}

export default Promotion;
