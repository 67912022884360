import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";

import CourseController from "../../../platform/api/course";
import CourseItem from "../../../platform/global/course-item";

import "./style.scss";

function Courses() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let response = await CourseController.GetCourseList(1, 1);
      setData(response.data.list);
    }

    fetchData();
  }, []);

  return (
    <div className="P-courses-page">
      <h1 className="G-gradient-text">ԴԱՍԸՆԹԱՑՆԵՐ</h1>
      <div className="P-course-list G-flex G-flex-wrap G-justify-between">
        {data.length ? (
          data.map((item, index) => <CourseItem data={item} key={index} />)
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default Courses;
