import { connection } from '../services/connection';

export default class SubscribeController {

  static subscribe = data => {
    return connection({
      url    : 'Subscribe/Subscribe',
      method : 'Post',
      body   : data
    });
  }

}

