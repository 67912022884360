import React, { useEffect, useState } from "react";
import { getImagePath } from "../../../../platform/api/images";
import ReviewController from "../../../../platform/api/review";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

function Reviews() {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let response = await ReviewController.GetList();
      setData(response.data)
    }

    fetchData()
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return data ? (
    <div className="P-home-reviews">
      <h2 className="G-gradient-text">Կարծիքներ</h2>
      <div className="P-reviews-list ">
        <Slider {...settings}>
          {data.map((item, index) => (
            <div className="P-review-item" key={index}>
              <img loading="lazy" src={getImagePath(item.imagePath)} alt="" width={170} height={170} />
              <h2 className="P-review-name">{item.name.hy}</h2>
              <h3 className="P-review-studied">{item.studied}</h3>
              <div className="P-review-comment">{item.comment}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  ) : null;
}

export default Reviews;
