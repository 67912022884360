import React, { useEffect, useState } from "react";
import BlogController from "../../../../platform/api/blog";
import { getImagePath } from "../../../../platform/api/images";
import BlogItem from "../../../../platform/global/blog-item";
import Loader from "../../../../components/Loader";
import Linkify from "react-linkify";
import { formatDate } from "../../../../platform/services/format-date";
import Video from "../../../../components/Video";
import "./style.scss";

function BlogDetails(props) {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState(null);
  const { id } = props.match.params;

  useEffect(() => {
    async function fetchDetails() {
      let response = await BlogController.GetDetails(id);
      setDetails(response.data);
    }

    async function fetchData() {
      let response = await BlogController.GetList();
      const filteredItems = response.data.filter(
        (value, index) => id !== value.slug
      );
      setData(filteredItems.slice(0, 3));
    }

    fetchDetails();
    fetchData();
  }, [id]);

  return (
    <div className="P-blog-details">
      {details ? (
        <>
          <div className="P-details-info">
            <h1>{details.name.hy}</h1>
            <h3 className="G-gray-color G-mt-3 G-mb-3">
              {formatDate(details.createdAt)}
            </h3>
          </div>
          <div
            className="P-details-cover G-mt-3"
            style={{
              backgroundImage: `url('${getImagePath(details.imagePath)}')`,
            }}
          ></div>

          <div className="P-details-description G-mt-3">
            <h2 className="G-mt-2 G-mb-2">{details?.subtitle?.hy}</h2>
            <h3 style={{ whiteSpace: "pre-wrap" }}>
              <Linkify>{details.description.hy.replace("<br/>", "\n")}</Linkify>
            </h3>
          </div>
          <Video />
          <div className="P-latest">
            <h2>Վերջին նորությունները</h2>
            <div className="G-flex G-align-center G-justify-between G-mt-6">
              {data.map((item, index) => (
                <BlogItem data={item} key={index} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default BlogDetails;
