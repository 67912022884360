import { connection } from '../services/connection';

export default class ReviewController {

  static GetList = () => {
    return connection({
      url    : 'Review',
      method : 'Get',
    });
  }
}

