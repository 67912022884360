import React, { useEffect, useState } from "react";
import BlogController from "../../../../platform/api/blog";
import BlogItem from "../../../../platform/global/blog-item";
import Loader from "../../../../components/Loader";
import "./style.scss";
function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let response = await BlogController.GetList();
      setData(response.data);
    }

    fetchData();
  }, []);

  return (
    <div className="P-blog-page G-page">
      <h1 className="G-gradient-text G-text-center">Բլոգ</h1>
      <div className="G-flex G-align-center G-justify-between G-flex-wrap">
        {data.length ? (
          data.map((item, index) => <BlogItem data={item} key={index} />)
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default Blog;
