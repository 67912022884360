import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getImagePath } from "../../api/images";
import ROUTES from "../../constants/routes";
import "./style.scss";

class CourseDetailsItem extends Component {
  state = {
    data: this.props.data,
  };

  render() {
    const data = this.state.data;
    return (
      <Link
        to={`${ROUTES.COURSE_DETAILS.replace(":id", data.slug)}`}
        className="P-course-details-item"
        style={{ backgroundImage: `url(${getImagePath(data.coverPhoto)})` }}
      >
        <div className="P-background-layer"></div>
        <div className="P-course-title G-flex G-align-center G-justify-center G-text-center">
          {data.name.hy}
        </div>
      </Link>
    );
  }
}

export default CourseDetailsItem;
