import React from "react";
import AboutValues from "../../components/Values";
import Video from "../../components/Video";
import Blog from "./components/blog";
import Courses from "./components/courses";
import HomeRegister from "./components/register";
import Reviews from "./components/reviews";
import Tutors from "./components/tutors";

import "./style.scss";

export default function Home() {
  return (
    <div className="P-home">
      <HomeRegister />
      <Courses />
      <AboutValues />
      <Tutors />
      <Blog />
      <Video />
      <Reviews />
    </div>
  );
}
