export const formatDate = (date) => {
    if (date) {
      const monthNames = [
        "հունվար",
        "փետրվար",
        "մարտ",
        "ապրիլ",
        "մայիս",
        "հունիս",
        "հուլիս",
        "օգոստոս",
        "սեպտեմբեր",
        "հոկտեմբեր",
        "նոյեմբեր",
        "դեկտեմբեր",
      ];
      const localDate = new Date(date);
      return `${localDate.getDate()} ${
        monthNames[localDate.getMonth()]
      } ${localDate.getFullYear()}`;
    }
    return "";
  };