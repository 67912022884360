import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/images/logo.svg";

import ROUTES from "../../platform/constants/routes";
import Modal from "../Modal";
import Register from "../Register";

import "./style.scss";

function Header() {
  const pathname = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("black");

  useEffect(() => {
    if (
      pathname === "/" ||
      pathname.includes("blog") ||
      pathname.includes("courses") ||
      pathname.includes("about") ||
      pathname.includes("faq") ||
      pathname.includes("cv")
    ) {
      setColor("black");
    } else {
      setColor("white");
    }
  }, [pathname]);

  return (
    <header className="G-flex G-align-center G-justify-between">
      <Link to={ROUTES.HOME} className="P-logo-block">
        <img width="140" height="140" src={Logo} alt="logo" loading="lazy" />
      </Link>
      <div className="P-header-links G-flex G-align-center G-ml-6">
        <NavLink
          activeClassName="active"
          to={ROUTES.COURSES}
          style={{ color: color }}
        >
          Դասընթացներ
        </NavLink>
        <NavLink
          activeClassName="active"
          to={ROUTES.BLOG_LIST}
          style={{ color: color }}
        >
          Բլոգ
        </NavLink>
        <NavLink
          activeClassName="active"
          to={ROUTES.ABOUT}
          style={{ color: color }}
        >
          Մեր մասին
        </NavLink>
        <NavLink
          activeClassName="active"
          to={ROUTES.FAQ}
          style={{ color: color }}
        >
          ՀՏՀ
        </NavLink>
      </div>
      <button
        style={{ color: color, border: `2px solid ${color}` }}
        className="G-btn"
        onClick={() => setIsOpen(true)}
      >
        ԳՐԱՆՑՎԵԼ
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Register onClose={() => setIsOpen(false)} />
      </Modal>
    </header>
  );
}

export default Header;
