import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ROUTES from "./platform/constants/routes";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home";

import ScrollToTop from "react-router-scroll-top";
import Blog from "./pages/blog/pages/list";
import BlogDetails from "./pages/blog/pages/details";
import CourseDetails from "./pages/courses/pages/details";
import AboutUs from "./pages/about";
import FAQ from "./pages/faq";
import Courses from "./pages/courses/list";
import Modal from "./components/Modal";
import { useEffect } from "react";
import Promotion from "./components/Promotion";
import PromotionModal from "./components/Promotion-Modal";
import Resume from "./components/Resume";
import CV from "./pages/cv";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("promotion")) {
      setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem("promotion", 1);
      }, 8000);
    }
  });

  return (
    <Router history={window.routerHistory}>
      <Promotion />
      <ScrollToTop>
        <Header />
        <Resume />
        <Switch>
          <Route path={ROUTES.HOME} exact component={Home} />
          <Route path={ROUTES.ABOUT} exact component={AboutUs} />
          <Route path={ROUTES.CV} exact component={CV} />
          <Route path={ROUTES.BLOG_LIST} exact component={Blog} />
          <Route path={ROUTES.FAQ} exact component={FAQ} />
          <Route path={ROUTES.COURSES} exact component={Courses} />
          <Route path={ROUTES.BLOG_DETAILS} exact component={BlogDetails} />
          <Route path={ROUTES.COURSE_DETAILS} exact component={CourseDetails} />
          <Redirect to={ROUTES.HOME} />
        </Switch>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <PromotionModal onClose={() => setIsOpen(false)} />
        </Modal>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
