export const connection = async (options) => {
  const HEADERS = new Headers();
  const CONTENT_TYPE = !options.contentType
    ? "application/json"
    : options.contentType;
  HEADERS.append("Content-Type", CONTENT_TYPE);

  const BODY = !options.contentType
    ? JSON.stringify(options.body)
    : options.body;
  const RESULTS = await fetch(
    `${
      options.url && `${"https://api.openskyitschool.com/"}api/${options.url}`
    }`,
    {
      method: options.method,
      headers: HEADERS,
      body: BODY,
    }
  );

  return RESULTS.json();
};
