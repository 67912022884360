import React from 'react';
import "./style.scss";

function Loader() {
    return (
        <div className="lds-default">
            <div className='lds1'></div>
            <div className='lds2'></div>
            <div className='lds3'></div>
            <div className='lds4'></div>
            <div className='lds5'></div>
            <div className='lds6'></div>
            <div className='lds7'></div>
            <div className='lds8'></div>
            <div className='lds9'></div>
            <div className='lds10'></div>
            <div className='lds11'></div>
            <div className='lds12'></div>
        </div>
    );
}

export default Loader;