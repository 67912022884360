import React from "react";
import Collapsible from "react-collapsible";

import "./style.scss";

export default function FAQ() {
  const faq = [
    {
      question: "Ինչպե՞ս գրանցվել դասընթացին:",
      answer:
        "Դասընթացին մասնակցելու համար անհրաժեշտ է դասընթացի  էջում կամ գլխավոր էջում սեղմել «Գրանցվել» կոճակին, ապա լրացնել Ձեր տվյալները: Մեր մասնագետը կապ կհաստատի Ձեզ հետ և կներկայացնի ընթացակարգը: Աշխատակիցը կապվում է գրանցված մասնակցի հետ 1-5 օրերի ընթացքում` կախված դասընթացի մեկնարկից:",
    },
    {
      question: "Ինչպե՞ս սովորել զեղչով:",
      answer:
        "Երկուսով գրանցվելիս դուք ստանում եք 20% զեղչ: Զեղչը հաշվարկվում է ինչպես տարբեր դասընթացներ ընտրելու դեպքում, այնպես էլ 2-րդ դասընթացին մասնակցելիս:",
    },
    {
      question: "Որտե՞ղ է գտնվում դպրոցը:",
      answer: "Մենք գտնվում ենք Զաքյան 8 հասցեում:",
    },
    {
      question: "Ինչպե՞ս կապվել Ձեզ հետ:",
      answer:
        "Մեզ հետ կարող եք կապվել +374 95 821084 հեռ.համարով, յուրաքանչյուր օր 10:00-ից մինչ 21:30:",
    },
    {
      question:
        "Դասընթացները նախատեսված են սկսնակների համար, թե անհրաժեշտ են նախնական գիտելիքներ:",
      answer:
        "Սովորաբար նախատեսված են սկսնակների համար, սկսում ենք ամենասկզբից: Ժամանակ առ ժամանակ ունենում ենք նաև կարճ սեմինարներ մասնագիտական աճի համար:",
    },
    {
      question: "Ավարտին որևէ փաստաթուղթ տրամադրվու՞մ է:",
      answer: "Այո, ավարտին տրվում է հավաստագիր` անգլերեն լեզվով:",
    },
  ];
  return (
    <div className="P-FAQ G-page G-flex G-align-center G-flex-column">
      <h1 className="G-gradient-text">ՀԱՃԱԽ ՏՐՎՈՂ ՀԱՐՑԵՐ</h1>
      {faq.map((item, index) => (
        <Collapsible key={index} trigger={item.question}>
          {item.answer}
        </Collapsible>
      ))}
    </div>
  );
}
