import React, { useState } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../platform/constants/routes";
import { useLocation } from "react-router-dom";

import "./style.scss";
import { useEffect } from "react";

function Resume() {
  const pathname = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [showResume, setShowResume] = useState(false);

  useEffect(() => {
    if (pathname.includes("cv")) {
      setShowResume(false);
    } else {
      setShowResume(true);
    }
  }, [pathname]);

  return showResume ? (
    <div
      className="P-resume-modal"
      style={{ height: isOpen ? "180px" : "50px" }}
    >
      <h2 onClick={() => setIsOpen(true)}>ՍՏԵՂԾԻ՜Ր ԳՐԱԳԵՏ ԻՆՔՆԱԿԵՆՍԱԳՐԱԿԱՆ</h2>
      {isOpen && (
        <>
          <span className="arrow" onClick={() => setIsOpen(!isOpen)}>
            &#129063;
          </span>
          <h3>
            Բացարձակ անվճար ստեղծիր պարզ և գրագետ կազմված ինքնակենսագրական
            հաշված րոպեների ընթացքում:
          </h3>
          <Link className="G-btn" to={ROUTES.CV}>
            ՍՏԵՂԾԵԼ ՀԻՄԱ
          </Link>
        </>
      )}
    </div>
  ) : null;
}

export default Resume;
