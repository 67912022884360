import React from "react";
import About4 from "../../assets/images/1.webp";
import About2 from "../../assets/images/2.webp";
import About3 from "../../assets/images/3.webp";
import "./style.scss";

function AboutValues() {
  return (
    <div className="P-about-values G-flex G-align-center G-flex-column">
      <h2>Մեր արժեքները</h2>
      <h3>
        Այն, ինչ մենք հաստատ չունենք, <br /> դա ձանձրալի դասախոսություններ են
      </h3>
      <div className="P-values G-flex G-align-center G-justify-between">
        <div>
          <img src={About4} alt="" />
          <h2>Մենք նորարար ենք</h2>
          <h3>
            Մեզ մոտ հնարավորություն ունեք սովորել 21-րդ դարի ամենաակտուալ
            մասնագիտությունները
          </h3>
        </div>
        <div>
          <img src={About2} alt="" />
          <h2>Մենք արագ ենք</h2>
          <h3>
            Մենք ունենք գերարագ հետադարձ կապ, որի շնորհիվ դիմելով այսօր, գուցե
            արդեն վաղը մասնակցեք դասընթացի
          </h3>
        </div>
        <div>
          <img src={About3} alt="" />
          <h2>Մենք վստահելի ենք</h2>
          <h3>
            Մեզ մոտ միայն վստահելի մասնագետներ են, նվազագույնը 4 տարվա
            աշխատանքային փորձով
          </h3>
        </div>
      </div>
    </div>
  );
}

export default AboutValues;
