import { connection } from '../services/connection';

export default class TutorController {

    static GetTutors = () => {
        return connection({
            url: 'Category',
            method: 'Get'
        });
    }
}

