import React from "react";
import video from "../../assets/images/video.mp4";

import "./style.scss";

function Video() {
  return (
    <video className="P-video" autoPlay muted loop>
        <source src={video} />
    </video>
  );
}

export default Video;
