import React from "react";
import Promotion from "../../assets/images/Promotion.webp";

import "./style.scss";

function PromotionModal(props) {
  return (
    <div className="P-promotion-modal G-flex G-flex-column G-align-center G-justify-center">
      <img src={Promotion} alt="" />
      <h2 className="G-mb-1">Ընկերների հետ սովորելը հետաքրքիր է</h2>
      <h3>
        Գրանցվիր դասընթացին ընկերոջդ հետ եվ յուրաքանչյուրդ ստացեք 20% զեղչ
      </h3>
      <span onClick={() => props.onClose()} className="P-icon-close">
        &#x2715;
      </span>
    </div>
  );
}

export default PromotionModal;
