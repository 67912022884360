import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import TutorController from "../../../../platform/api/tutor";
import TutorItem from "../../../../platform/global/tutor-item";
import Slider from "react-slick";
import ROUTES from "../../../../platform/constants/routes";

import "./style.scss";

function Tutors() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let response = await TutorController.GetTutors();
      setData(response.data);
    }

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return data.length ? (
    <div className="P-home-tutors G-flex G-flex-column">
      <Link to={ROUTES.ABOUT} className="G-gradient-text">Մեր թիմը</Link>
      <div className="P-tutors-slider ">
        <Slider {...settings}>
          {data.map((item, index) => (
            <TutorItem data={item} key={index} />
          ))}
        </Slider>
      </div>
    </div>
  ) : null;
}

export default Tutors;
