import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import animation from "./animation.json";
import Modal from "../../components/Modal";
import Validator from "../../platform/services/validator";
import SubscribeController from "../../platform/api/subscribe";

import Video from "../../components/Video";
import { shuffle } from "../../platform/services/random";
import CourseController from "../../platform/api/course";
import CourseDetailsItem from "../../platform/global/course-details";

import "./style.scss";

function CV() {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const url =
    "https://docs.google.com/document/d/16bP1va1hoe54W7WZui12hH-ZLkDBZIjSxS8WHMglQws/edit";

  const submit = async () => {
    if (Validator.isEmailValid(email)) {
      const res = await SubscribeController.subscribe({
        email: email,
      });
      if (res.success) {
        window.location.assign(url);
        setIsOpen(false);
        setMessage("");
      } else {
        setMessage(res.message);
      }
    } else {
      setMessage("Սխալ Էլ. փոստի հասցե");
    }
  };

  useEffect(() => {
    async function fetchData() {
      let response = await CourseController.GetCourseList(1, 1);
      const items = shuffle(response.data.list);
      setData(items.slice(0, 3));
    }

    fetchData();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  return (
    <div className="P-resume-page">
      <div className="P-resume-mobile">
        <h2>
          Ինքնակենսագրական ստեղծելու համար մուտք գործեք <span>համակարգչով</span>
        </h2>
        <Lottie
          style={{
            zIndex: 10,
          }}
          className="P-resume-animation"
          options={defaultOptions}
          height={200}
          width={200}
        />
      </div>
      <div className="P-resume-tag G-flex G-align-center G-justify-center G-flex-column">
        <Lottie
          style={{
            position: "absolute",
            left: "80px",
            top: "55%",
            zIndex: 10,
          }}
          className="P-resume-animation"
          options={defaultOptions}
          height={200}
          width={200}
        />
        <h1>
          Ստեղծի՜ր
          <br /> <span>ինքնակենսագրական</span> <br /> հաշված րոպեների ընթացքում
        </h1>
        <h2>Պարզ, հեշտ և հասանելի</h2>
        <button className="G-btn" onClick={() => setIsOpen(true)}>
          Ստեղծել հիմա
        </button>
      </div>
      <div className="P-resume-content">
        <h2>● Ուշադիր եղեք կոնտակտային տվյալներին</h2>
        <h3>
          Համոզվեք, որ Ձեր էլ. փոստի հասցեն, հեռ.համարը, գտնվելու վայրը ներառված
          են և ճշգրիտ են: Այնուամենայնիվ, մի գրեք Ձեր ամբողջական հասցեն, միայն
          քաղաքը և երկիրը: Էլ.փոստի հասցեն ևս պետք է լինի պրոֆեսիոնալ, առանց
          ավելորդ բառերի:
        </h3>
        <h2>
          ● Ինքնակենսագրականը համապատասխանեցրեք այն հաստիքին, որի համար դիմում
          եք:
        </h2>
        <h3>
          Աշխատանք փնտրողների ամենամեծ սխալը կայանում է նրանում, որ ստեղծում են
          մեկ CV և ուղարկում տարբեր հաստիքներին դիմելիս: Ցանկալի է ունենալ մի
          քանի CV-ներ կամ նախօրոք համապատասխանեցնել այն հաստիքին, որին դիմում
          եք: Խոսքը վերաբերում է նաև ոչ ակտուալ աշխատանքային փորձին, որը կարող է
          ոչ կարևոր լինել տվյալ հաստիքին դիմելիս:
        </h3>
        <h2>● Մանրակրկիտ ներկայացրեք աշխատանքային փորձի նկարագիրը </h2>
        <h3>
          Երբ խոսքը գնում է աշխատանքային փորձի մասին, բոլորն էլ գիտեն, որ պետք է
          նշել աշխատավայրի անունը, աշխատանքի տարիները և հաստիքը: Սակայն այստեղ
          կարևոր է ևս երկու կետ ավելացնել՝ այն պարտականությունները, որոնք
          ունեցել եք և այն բարեփոխումները, որոնք իրականացրել եք:
        </h3>
        <h2> ● Օգտագործեք ինքնակենսագրականի PDF ֆորմատը</h2>
        <h3>
          Չմոռանաք նաև, որ CV-ն պետք է արտահանել PDF-ֆորմատով: Նախկին word
          ֆայլերն այլևս ակտուալ չեն:
        </h3>
      </div>
      <Video />
      <div className="P-details-courses G-mt-6">
        <h2>Առաջիկա դասընթացները</h2>
        <div className=" G-flex G-align-center G-justify-between G-mt-6">
          {data.map((item, index) => (
            <CourseDetailsItem data={item} key={index} />
          ))}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setMessage("");
        }}
      >
        <div className="P-resume-subscribe G-flex G-align-center G-justify-center G-flex-column">
          <h2>ԵՂԻՐ ՏԵՂԵԿԱՑՎԱԾ</h2>
          <h3 className="G-mt-2">
            Բաժանորդագրվիր ՏՏ ոլորտի նորություններն առաջինը ստանալու համար:
          </h3>
          <h4 className="G-mt-2">էլեկտրոնային հասցե</h4>
          <input
            className="G-mt-2"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span>{message}</span>
          <button onClick={submit} className="G-btn G-mt-2">
            Բաժանորդագրվել
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CV;
