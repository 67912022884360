import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CourseItem from "../../../../platform/global/course-item";
import CourseController from "../../../../platform/api/course";

import Right from "../../../../assets/images/right.png";
import ROUTES from "../../../../platform/constants/routes";

import "./style.scss";

function Courses() {
  const [data, setData] = useState([]);
  const [step, setStep] = useState(0);
  const stepCount = Math.ceil(data.length / 3);

  useEffect(() => {
    async function fetchData() {
      let response = await CourseController.GetCourseList(1, 1);
      setData(response.data.list);
    }

    fetchData();
  }, []);

  const moveSlide = (direction) => {
    if (direction === "prev" && step !== stepCount - 1) {
      setStep(step + 1);
    } else if (direction === "next" && step !== 0) {
      setStep(step - 1);
    }
  };

  const showNext = () => {
    return step !== stepCount - 1;
  };

  return data.length ? (
    <div className="P-home-courses" id="courses">
      <Link to={ROUTES.COURSES} className="G-gradient-text">
        ԴԱՍԸՆԹԱՑՆԵՐ
      </Link>
      {showNext() && (
        <img
          src={Right}
          alt="arrow right"
          className="S-modal-categories-right-btn"
          onClick={() => moveSlide("prev")}
        />
      )}
      {!!step && (
        <img
          src={Right}
          alt="arrow left"
          className="S-modal-categories-left-btn"
          onClick={() => moveSlide("next")}
        />
      )}
      <div
        className="P-courses G-flex G-align-center G-justify-between"
        style={{ left: step * -100 + "%" }}
      >
        {data.map((item, index) => (
          <CourseItem data={item} key={index} />
        ))}
      </div>
    </div>
  ) : null;
}

export default Courses;
