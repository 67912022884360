import { connection } from '../services/connection';

export default class BlogController {

  static GetList = () => {
    return connection({
      url    : 'Promotion',
      method : 'Get',
    });
  }

  static GetDetails = id => {
    return connection({
      url    : `Promotion/Details/${id}`,
      method : 'Get',
    });
  }
}

