import React, { useState } from "react";
import UserController from "../../platform/api/user";
import Validator from "../../platform/services/validator";
import SuccessMessage from "../Success-message";

import "./style.scss";

function Register(props) {
  const [data, setData] = useState({
    name: "",
    phone: "",
    lesson: "",
    email: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    if (
      Validator.isPhoneValid(data.phone) &&
      data.lesson.length > 0 &&
      data.name.length > 0 &&
      Validator.isEmailValid(data.email)
    ) {
      setIsLoading(true);
      await UserController.sendInfo(data);
      setIsOpen(true);
      await UserController.register(data);
      await UserController.sendEmailToUser(data);
      setIsLoading(false);
    }
  };

  const close = () => {
    setData({
      name: "",
      lesson: "",
      phone: "",
      email: "",
    });
    props.onClose();
  };

  const change = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div className="P-apply-modal G-flex  G-flex-column">
      <h2 className="G-text-center">ԳՐԱՆՑՈՒՄ</h2>
      <h3 className="G-mt-2 G-text-center">
        Գրանցման համար լրացրեք բոլոր անհրաժեշտ տվյալները և մենք հնարավորինս արագ
        կկապվենք Ձեզ հետ:
      </h3>
      <div className="P-apply-form G-flex G-align-center G-flex-column G-mt-2">
        <div className="G-flex G-flex-column G-flex-start">
          <span>Անուն Ազգանուն</span>
          <input
            type="text"
            name="name"
            autoComplete="off"
            onChange={change}
            placeholder="Նշեք Ձեր Անուն Ազգանունը"
          />
        </div>
        <div className="G-flex G-flex-column G-flex-start">
          <span>Հեռախոսահամար</span>
          <input
            type="number"
            name="phone"
            autoComplete="off"
            onChange={change}
            placeholder="Նշեք Ձեր հեռ.համարը 098123456 ֆորմատով"
          />
        </div>
        <div className="G-flex G-flex-column G-flex-start">
          <span>էլեկտրոնային հասցե</span>
          <input
            type="email"
            name="email"
            autoComplete="off"
            onChange={change}
            placeholder="Նշեք գործող էլ.փոստի հասցե, որտեղ կստանաք նամակ-հաստատում"
          />
        </div>
        <div className="G-flex G-flex-column G-flex-start">
          <span>Դասընթացը, որին ցանկանում եք միանալ</span>
          <input
            type="text"
            name="lesson"
            autoComplete="off"
            onChange={change}
            placeholder="Նշեք դասընթացը, որին ցանկանում եք միանալ"
          />
        </div>
        <button
          onClick={submit}
          style={{ pointerEvents: isLoading && "none" }}
          className="G-btn"
        >
          ԳՐԱՆՑՎԵԼ
        </button>
      </div>
      <SuccessMessage isOpen={isOpen} close={close} />
      <span onClick={() => props.onClose()} className="P-icon-close">
        &#x2715;
      </span>
    </div>
  );
}

export default Register;
