class Validator {

  static isEmailValid = email => {
    const regexp = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
    return regexp.test(email);
  }

  static isPhoneValid = phone => {
    const regexp = /(([+374]{4}|[0]{1}))?([1-9]{2})(\d{6})/;
    return regexp.test(phone);
  }
}

export default Validator;