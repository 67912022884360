import React from "react";
import { getImagePath } from "../../api/images";

import "./style.scss";

function TutorItem(data) {
  return (
    <div className="P-tutor-item G-flex G-flex-column G-align-center G-justify-center">
      <h2 className="P-tutor-name">{data?.data.name.hy}</h2>
      <h3 className="P-tutor-working">{data?.data.workingPlace}</h3>
      <h4 className="P-tutor-position">{data?.data.workingPosition}</h4>
      <img loading="lazy" src={getImagePath(data?.data.imagePath)} alt="" width={200} height={200}/>
    </div>
  );
}

export default TutorItem;
