const ROUTES = {
    HOME          : '/',
    BLOG_LIST     : '/blog',
    BLOG_DETAILS  : '/blog/:id',
    COURSES       : '/courses',
    COURSE_DETAILS: '/:id',
    ABOUT         : '/about',
    CV            : '/cv',
    GALLERY       : '/gallery',
    FAQ           : '/faq'
  };
  
export default ROUTES;
  