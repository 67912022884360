import { connection } from '../services/connection';

export default class CourseController {

    static GetCourseList = (count, page) => {
        return connection({
            url: `Company/WithDistance?count=${count}&page=${page}`,
            method: 'Get'
        });
    }

    static GetDetails = (id) => {
        return connection({
            url: `Company/Details/${id}`,
            method: 'Get'
        });
    }

    static GetMore = (count, page, data) => {
        return connection({
            url: `Company/GetMore?count=${count}&page=${page}`,
            method: 'Post',
            body : data
        });
    }
}

