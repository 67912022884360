import React, { useEffect, useState } from "react";
import BlogItem from "../../../../platform/global/blog-item";
import BlogController from "../../../../platform/api/blog";

import "./style.scss";
import { Link } from "react-router-dom";
import ROUTES from '../../../../platform/constants/routes'

function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let response = await BlogController.GetList();
      setData(response.data.slice(0, 3))
    }

    fetchData()
  }, [])

  return (
    data.length ?
      <div className="P-home-blog" >
        <Link to={ROUTES.BLOG_LIST} className="G-gradient-text">Բլոգ</Link>
        <div className="P-blogs G-flex G-justify-between">
          {data.map((item, index) => (
            <BlogItem data={item} key={index} />
          ))}
        </div>
      </div> : null
  );
}

export default Blog;
